import React from "react";
import AdminDrawer from "./components/AdminDrawer";
import { Box, Typography } from "@mui/material";
import { TranslateContext } from "../../context/TranslateContext";
import ManageUser from "../manage-users/ManageUser";
import Plans from "../plans/Plans";
import ApiKeys from "../api-keys/ApiKeys";
import PaymentGateway from "../payment-gateway/PaymentGateway";
import PartnersLogo from "../partners-logo/PartnersLogo";
import Faq from "../faq/Faq";
import ManagePage from "../manage-page/ManagePage";
import Testimonial from "../testimonial/Testimonial";
import AppConfig from "../appCOnfig/AppConfig";
import SMTP from "../SMTP/SMTP";
import Translation from "../translation/Translation";
import ContactForm from "../contactForm/ContactForm";
import Dash from "./dash/Dash";
import UpdateApp from "../updateApp/UpdateApp";
import ThemeSetting from "../theme/ThemeSetting";
import LogOutAdmin from "../logout/LogOutAdmin";
import AdminProfile from "../profile/AdminProfile";

const AdminDashboard = () => {
  const { data } = React.useContext(TranslateContext);

  const [page, setPage] = React.useState(null);
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get("page");

  React.useEffect(() => {
    setPage(foo || "dashboard");
  }, [foo]);

  return (
    <div>
      <AdminDrawer page={page} setPage={setPage} data={data}>
        {page === "dashboard" && <Dash data={data} />}
        {page === "users" && <ManageUser data={data} />}
        {page === "plans" && <Plans data={data} />}
        {page === "api-keys" && <ApiKeys data={data} />}
        {page === "payment-gateway" && <PaymentGateway data={data} />}
        {page === "partners" && <PartnersLogo data={data} />}
        {page === "faq" && <Faq data={data} />}
        {page === "manage-page" && <ManagePage data={data} />}
        {page === "testimonial" && <Testimonial data={data} />}
        {page === "app-config" && <AppConfig data={data} />}
        {page === "smtp" && <SMTP data={data} />}
        {page === "translation" && <Translation data={data} />}
        {page === "leads" && <ContactForm data={data} />}
        {page === "update-app" && <UpdateApp data={data} />}
        {page === "theme-setting" && <ThemeSetting data={data} />}
        {page === "profile" && <AdminProfile data={data} />}
        {page === "logout" && <LogOutAdmin data={data} />}
      </AdminDrawer>
    </div>
  );
};

export default AdminDashboard;

import React, { useContext, useEffect, useState, useRef } from "react";
import { TranslateContext } from "../context/TranslateContext";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import TextFieldNew from "../common/TextFieldNew";
import { ChatRounded } from "@mui/icons-material";
import { GlobalContext } from "../context/GlobalContext";
import MessagesArr from "./MessagesArr";

const Embed = () => {
  const { hitAxios } = useContext(GlobalContext);
  const [state, setState] = useState({
    chatting: false,
    question: "",
  });
  const { data } = useContext(TranslateContext);
  const [id, setId] = useState(null);
  const [height, setHeight] = useState(null);
  const [title, setTitle] = useState(null);
  const [input, setInput] = useState("");
  const [chatMsg, setChatMsg] = useState([]);
  const chatBoxRef = useRef(null); // Ref for the chat message container

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let foo = params.get("id");
  let heightFoo = params.get("height");
  let titleFoo = params.get("title");

  async function startChat(params) {
    const getChatId = localStorage.getItem("chat_id");
    const res = await hitAxios({
      path: "/api/embed/start_chat",
      post: true,
      admin: false,
      obj: { ...state, botId: id, chatId: getChatId || null },
    });
    if (res.data.success) {
      setState({ ...state, chatting: true, chatData: res?.data?.chat });
      setChatMsg(res.data.msgArr);
      localStorage.setItem("chat_id", res?.data?.chat?.chat_id || null);

      if (res.data.refresh) {
        localStorage.removeItem("chat_id");
        window.location.reload();
      }
    }
  }

  async function getReply(chatId, question) {
    const res = await hitAxios({
      path: "/api/embed/get_reply",
      post: true,
      admin: false,
      obj: { chatId, question },
    });

    if (res.data.success) {
      const msg = res.data.data;
      setChatMsg((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages.pop();
        return [...updatedMessages, ...msg];
      });
      setInput("");
    }
  }

  useEffect(() => {
    setId(foo);
    setHeight(heightFoo);
    setTitle(titleFoo);
  }, [foo, heightFoo, titleFoo]);

  useEffect(() => {
    // Scroll to the bottom when chatMsg is updated
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chatMsg]);

  return (
    <Container maxWidth="sm">
      {state.chatting ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: `${height || 100}vh`,
            bgcolor: "action.hover",
          }}
        >
          <Box
            borderRadius={2}
            sx={{ bgcolor: (t) => t.palette.primary.main, color: "white" }}
            p={2}
          >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <ChatRounded />
              <Typography>{title || `${state?.name} 👋🏻`}</Typography>
            </Stack>
          </Box>
          <Box
            ref={chatBoxRef} // Reference for the chat box
            flexGrow={1}
            overflow="auto"
            mb={2}
            p={1}
            borderRadius={1}
          >
            {chatMsg.map((msg, index) => (
              <MessagesArr index={index} msg={msg} />
            ))}
          </Box>
          <Box p={1} display="flex" mt="auto">
            <TextFieldNew
              fullWidth
              variant="outlined"
              placeholder={data.typeMsgTOStartChatting}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter")
                  getReply(state?.chatData?.chat_id, input);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => getReply(state?.chatData?.chat_id, input)}
              style={{ marginLeft: "8px" }}
            >
              {data.send}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: `${height || 100}vh`,
            bgcolor: "action.hover",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box width={"80%"} borderRadius={2} bgcolor={"white"} p={2}>
            <Stack direction={"column"} spacing={2}>
              <Typography align="center">
                {data.pleaseFillDetailsToStartChat}
              </Typography>

              <TextFieldNew
                value={state.name}
                onChange={(e) => setState({ ...state, name: e.target.value })}
                label={data.name}
              />
              <TextFieldNew
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                label={data.email}
              />
              <TextFieldNew
                type="number"
                value={state.mobile}
                onChange={(e) => setState({ ...state, mobile: e.target.value })}
                label={data.mobile}
              />

              <Button onClick={startChat} variant="contained">
                {data.submit}
              </Button>
            </Stack>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Embed;

import React from "react";
import TitlePanel from "../../common/TitlePanel";
import { Box, Avatar, Stack, TextField, Button } from "@mui/material";
import { AccountTreeOutlined, Close } from "@mui/icons-material";
import { GlobalContext } from "../../context/GlobalContext";

const AdminProfile = ({ data }) => {
  const [admin, setAdmin] = React.useState("");
  const { hitAxios } = React.useContext(GlobalContext);
  const inputPorps = { style: { borderRadius: 10 } };

  async function getAdmin() {
    const res = await hitAxios({
      path: "/api/admin/get_admin",
      post: false,
      admin: true,
      token_user: localStorage.getItem(process.env.REACT_APP_TOKEN + "_admin"),
    });
    if (res.data.success) {
      setAdmin(res.data.data);
    }
  }

  async function updateAdmin() {
    const res = await hitAxios({
      path: "/api/admin/update-admin",
      post: true,
      admin: true,
      obj: admin,
    });
  }

  React.useEffect(() => {
    getAdmin();
  }, []);

  return (
    <div>
      <Box mt={2}>
        <TitlePanel
          icon={
            <Avatar
              sx={{
                width: 50,
                height: 50,
                bgcolor: "action.hover",
              }}
            >
              <AccountTreeOutlined
                sx={{
                  color: (t) => t.palette.primary.main,
                }}
              />
            </Avatar>
          }
          data={data}
          place={data.profile}
          title={data.profile}
        />

        <Box mt={4}>
          {admin && (
            <Stack direction={"column"} spacing={2} mt={2}>
              <TextField
                onChange={(e) => setAdmin({ ...admin, email: e.target.value })}
                value={admin.email}
                InputProps={inputPorps}
                size="small"
                label={data.email}
                fullWidth
              />

              <TextField
                onChange={(e) =>
                  setAdmin({ ...admin, newpass: e.target.value })
                }
                InputProps={inputPorps}
                size="small"
                label={data.password}
                fullWidth
              />

              <Button
                onClick={updateAdmin}
                variant="contained"
                sx={{ borderRadius: 2, textTransform: "none", boxShadow: 0 }}
                fullWidth
              >
                {data.submit}
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default AdminProfile;
